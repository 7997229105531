import React, {useState, useEffect} from 'react';

function App() {

  const [pago, setPago] = useState(100);
  const [retorno, setRetorno] = useState('');

  useEffect(() => {
    console.log('RETORNO: ', retorno);
  }, [retorno]);

  const pagar = () => {
    var ret = '';
    if(window.Android) ret = window.Android.showToast(`SE MANDA PAGAR: ${pago}`);
    else ret = 'SIN ANDROID';
    setRetorno(ret);
  }

  return (
    <div className="App">
      <br /><br /><br />
      <textarea name="retorno" rows="10" cols="50" defaultValue={retorno}></textarea><br />
      <input type="numeric" value={pago} onChange={(e) => setPago(e.target.value)} />
      <button onClick={() => pagar()}>PAGAR</button>
    </div>
  );
}

export default App;
